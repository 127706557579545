'use client';
/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import { useEffect, useRef, useState } from 'react';

type Props = {
  children?: React.ReactElement[];
  speed?: number;
  height?: number;
};

const SlideShow = styled.div<{ height: number }>`
  overflow: hidden;
  max-width: 802px;
  height: ${({ height }) => height}px;
  border-radius: 8px;
`;

const SlideShowSlider = styled.div<{ delta?: number }>`
  white-space: nowrap;
  transition: ease 1000ms;
  transform: ${({ delta }) => (delta ? `translate3d(0, -${delta}px, 0)` : 0)};
`;

export const CarouselWords = ({ children, speed = 5000, height = 40 }: Props) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const len = children?.length || 0;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => setIndex(prevIndex => (prevIndex === len - 1 ? 0 : prevIndex + 1)), speed);

    return () => {
      resetTimeout();
    };
  }, [index, len, speed]);

  return (
    <SlideShow height={height}>
      <SlideShowSlider delta={index * height}>{children}</SlideShowSlider>
    </SlideShow>
  );
};

export default CarouselWords;
