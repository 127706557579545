import HttpClient from '@fe-common-utils/libs/apis';

const headers = { 'X-platform': 'WEB' };

// TODO: 待重新思考，在 storybook 上執行時，環境變數的取得方式是從 `import.meta.env` 取得，但在 Next.js 上執行時，會發生 `Cannot use ‘import.meta‘ outside a module` 錯誤，而 Next.js 的環境變數需要在 build 時就先綁定，無法利用變數指定，所以解法可能是 fe-common-ui 元件需要先被編譯後，再被其它專案引用使用

// 如果單獨執行 storybook，則會以 vite build 方式建立，這時的環境變數要從 `import.meta.env` 取得
// 但如果是綁定在 Next.js 上，這時的環境變數要從 `process.env` 取得
// const environment = process.env ? process.env : import.meta.env;

export const apiClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_API_URL), headers });

export const wsApiClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_REUTER_API_URL), headers });

export const essApiClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_SEARCH_INFO_URL) });
