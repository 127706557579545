'use client';

// eslint-disable-next-line import/no-extraneous-dependencies
import { isServer } from '@fe-common-utils/libs/utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const domain = (): string => {
  if (isServer) return '';

  const hostname = window.location.hostname;
  const domainPosition = /beta|stage/.test(hostname) ? -3 : -2;

  return hostname.split('.').slice(domainPosition).join('.');
};

export const path = () => {
  if (isServer) return '';

  return window?.location?.pathname;
};

export const createCookie = ({
  key,
  value,
  options,
}: {
  key: string;
  value: unknown;
  options?: { domain?: string; expires?: Date; path?: string };
}): void => {
  const cookieExpire = Date.now() + 30 * 24 * 60 * 60 * 1000;
  const cookieOption = options ?? {
    domain: domain(),
    expires: new Date(cookieExpire),
  };

  cookies.set(key, value, cookieOption);
};

export const getCookie = (key: string, initialValue?: unknown) => {
  return cookies.get(key) || initialValue;
};

/**
 * 刪除 cookie 時，記得要將 path 和 domain 傳入，不然無法正確刪除 cookie
 */
export const removeCookie = (key: string, options?: unknown): void => {
  const cookieOption = options ?? {
    domain: domain(),
  };
  cookies.remove(key, cookieOption);
};
