// TODO: 待重構
'use client';

import dynamic from 'next/dynamic';
// import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import LoadingAnimationBar from '@fe-common-ui/LoadingSkeleton/LoadingAnimationBar';
import { styled } from '@linaria/react';

const View = styled.div`
  padding: 0 16px;
`;

const MobileSearch = dynamic(() => import('@fe-news/components/IndexMobileWrapper/MobileSearch/MobileSearch'), {
  loading: () => (
    <View>
      <LoadingAnimationBar height={40} />
    </View>
  ),
  ssr: false
});

const DynamicMobileSearch = () => {
  return <MobileSearch />;
};

export default DynamicMobileSearch;
