'use client';

import ReactSlick from '@fe-common-ui/Slick';
import { styled } from '@linaria/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import ColumnistsCard from './ColumnistsCard';
import DotsWrapper from '@fe-news/components/IndexMobileWrapper/NavigatorCarousel/DotsWrapper';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

const StyledSlider = styled.div<{ isDesktop: boolean }>`
  position: relative;
  background-color: #fff;
  padding-bottom: 27px;

  img {
    border-radius: 10px;
  }

  &:hover {
    #left-arrow-touch,
    #right-arrow-touch {
      @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
        display: block;
      }
    }
  }

  .slick-slide {
    margin: ${({ isDesktop }) => (isDesktop ? '0' : '0px 6.5px')};
  }

  .slick-track {
    display: ${({ isDesktop }) => (isDesktop ? 'block' : 'flex')};
  }
`;

const ArrowTouch = styled.button`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: transparent;
  z-index: 2;
  display: none;
`;

interface ColumnistsCarouselInterface {
  columnistData: ColumnistsType[];
}

const ColumnistsCarousel: React.FC<ColumnistsCarouselInterface> = ({ columnistData }) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const sliderReference = useRef<null | {
    slickNext: () => void;
    slickPrev: () => void;
  }>(null);
  const isEmpty = columnistData?.length === 0;

  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);

  const settings = useMemo(
    () => ({
      dots: false,
      className: isDesktop ? '' : 'center',
      centerMode: !isDesktop,
      infinite: true,
      centerPadding: '13px',
      slidesToShow: 5,
      slidesToScroll: isDesktop ? 5 : 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      beforeChange: (_: number, newIndex: number) => {
        const index = isDesktop && newIndex > 0 ? newIndex - 4 : newIndex;

        setScrollIndex(index);
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }),
    [isDesktop]
  );

  const onForceSlide = useCallback(
    (back = false) =>
      () => {
        if (sliderReference.current) {
          if (back) {
            sliderReference.current.slickNext();
          } else {
            sliderReference.current.slickPrev();
          }
        }
      },
    []
  );

  if (isEmpty) return null;

  const newsNumber = isDesktop ? columnistData.length / 5 : columnistData.length;

  return (
    <StyledSlider isDesktop={isDesktop}>
      <ArrowTouch style={{ left: -10 }} onClick={onForceSlide()} id='left-arrow-touch'>
        <Image src='/assets/icons/arrows/symbol-button-icon-back.png' alt='arrow back' width={36} height={36} />
      </ArrowTouch>
      <ReactSlick ref={sliderReference} {...settings}>
        {columnistData.map(columnist => {
          return <ColumnistsCard key={columnist.columnistsId} data={columnist} />;
        })}
      </ReactSlick>
      <DotsWrapper newsNumber={newsNumber} scrollIndex={scrollIndex} />
      <ArrowTouch style={{ right: -5 }} onClick={onForceSlide(true)} id='right-arrow-touch'>
        <Image src='/assets/icons/arrows/symbol-button-icon-next.png' alt='arrow back' width={36} height={36} />
      </ArrowTouch>
    </StyledSlider>
  );
};

export default ColumnistsCarousel;
