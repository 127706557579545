/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { createCookie, getCookie } from '@fe-common-utils/libs/cookie';
import { isClient } from '@fe-common-utils/libs/utils';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import Image from 'next/image';

type PlatformType = 'ios' | 'android';

type ConfigType = {
  appMeta?: string;
  storeLink?: string;
};

interface ExtendedNavigator extends Navigator {
  standalone?: boolean;
}

const SMART_BANNER_COOKIE_KEY = 'smart-banner-closed';
export const BANNER_HEIGHT = 54;
export const SMART_BANNER_CLOSE_EVENT = 'closeSmartBanner';

const OS_TYPE = {
  IOS: 'iOS',
  ANDROID: 'Android'
};

const appConfig = {
  ios: {
    appMeta: 'apple-itunes-app',
    storeLink: 'https://apps.apple.com/tw/app/id'
    // iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon'],
  },
  android: {
    appMeta: 'google-play-app',
    storeLink: 'http://play.google.com/store/apps/details?id='
    // iconRels: [
    //   'android-touch-icon',
    //   'apple-touch-icon-precomposed',
    //   'apple-touch-icon',
    // ],
  }
};

const oneDayLater = new Date(Date.now() + 1000 * 60 * 60 * 24);

export const loadSmartBannerIsCloseFromCookie = () => {
  const agent = new UAParser(isClient ? window.navigator.userAgent : '')?.getResult() ?? {};

  if (!(agent.os.name === OS_TYPE.IOS || agent.os.name === OS_TYPE.ANDROID)) {
    return true;
  }

  return getCookie(SMART_BANNER_COOKIE_KEY, false);
};

const Container = styled.div<{ deepMode: boolean }>`
  .smart-banner {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    border: solid 1px $gray-e9e9e9;
    padding: 7px 3px 7px 6px;
    z-index: 5;
    background-color: ${({ deepMode }) => (deepMode ? '#323814' : '#ddf0f4')};

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      display: flex;
    }

    @media screen and (max-width: 374px) {
      padding: 7px 0;
    }

    &.hide {
      display: none;
      height: 0;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;

      &--close {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin-right: 6px;
        background-color: transparent;
        font-weight: normal;
        cursor: pointer;
      }

      &--logo {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        background: url('/assets/icons/smartBanner/icon-anue-app-round.svg') center no-repeat;
      }

      p {
        text-align: left;
        color: ${({ deepMode }) => (deepMode ? '#fff' : '#515c69')};
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;

        @media screen and (max-width: 374px) {
          font-size: 10px;
        }

        span {
          letter-spacing: -0.2px;
          color: ${({ deepMode }) => (deepMode ? '#b5b7bb' : '#364c56')};

          @media screen and (max-width: 374px) {
            -webkit-transform: scale(0.75);
            display: inline-block;
            margin-left: -20px;
            width: 156px;
          }
        }

        strong {
          font-size: 17px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          color: $black;
        }
      }
    }

    &__download {
      cursor: pointer;
      padding-top: 3px;
    }

    &__free-try {
      cursor: pointer;
      padding-top: 3px;
    }
  }
`;

const SmartBanner = ({
  onShow = () => {},
  onClose = () => {},
  onLoaded = () => {},
  slogan = '無廣告環境，掌握最新財經情報',
  deepMode = false
}) => {
  const [type, setType] = useState('');
  const [appId, setAppId] = useState('');
  const [settings, setSettings] = useState({});
  const [closed, setClosed] = useState(false);
  const isCloseBanner = loadSmartBannerIsCloseFromCookie();

  const determineType = () => {
    if (!isClient) return;

    const agent = new UAParser(window.navigator.userAgent)?.getResult() ?? {};

    if (agent.os.name === OS_TYPE.IOS) {
      setType('ios');
    } else if (agent.os.name === OS_TYPE.ANDROID) {
      setType('android');
    }
  };

  const setSettingsByType = useCallback(() => {
    if (type) {
      setSettings(appConfig[type as PlatformType]);
    }
  }, [type]);

  const parseAppId = useCallback(() => {
    if (!isClient || !settings) return;

    const meta = window.document.querySelector(`meta[name="${(settings as ConfigType)?.appMeta}"]`);
    if (!meta) return;

    let parsedAppId: string = '';
    if (type === 'windows') {
      parsedAppId = meta.getAttribute('content') as string;
    } else {
      const content = /app-id=([^\s,]+)/.exec(meta?.getAttribute('content') ?? '');
      parsedAppId = content && content[1] ? content[1] : '';
    }

    setAppId(parsedAppId);
    if (parsedAppId && typeof onShow === 'function') {
      onShow();
    }
  }, [onShow, settings, type]);

  const closeHandler = () => {
    setClosed(true);
    window.dispatchEvent(new CustomEvent(SMART_BANNER_CLOSE_EVENT));

    if (typeof onClose === 'function') {
      onClose();

      createCookie({
        key: SMART_BANNER_COOKIE_KEY,
        value: 1,
        options: { path: '/', expires: oneDayLater }
      });
    }
  };

  const downloadHandler = () => {
    const link = `${(settings as ConfigType).storeLink}${appId}`;
    if (isClient) window.open(link);
    closeHandler();
  };

  useEffect(() => {
    if (!isCloseBanner) {
      determineType();
    }

    onLoaded();
  }, [isCloseBanner, onLoaded]);

  useEffect(() => {
    setSettingsByType();
  }, [setSettingsByType, type]);

  useEffect(() => {
    parseAppId();
  }, [parseAppId, settings]);

  // Render logic remains largely the same
  if (!isClient || !type || (window.navigator as ExtendedNavigator).standalone || isCloseBanner || !appId) {
    return null;
  }

  return (
    <Container deepMode={deepMode}>
      <div className={cx('smart-banner', { hide: closed })}>
        <div className={cx('smart-banner__content')}>
          <button
            type='button'
            className={cx('smart-banner__content--close')}
            aria-label='close'
            onClick={closeHandler}
          >
            <Image
              src={`/assets/icons/smartBanner/${deepMode ? 'icon-close-deep-mode' : 'icon-close'}.png`}
              alt='close'
              width={22}
              height={22}
            />
          </button>
          <div className={cx('smart-banner__content--logo')} />
          <p>
            <strong>Anue鉅亨App</strong>
            <br />
            <span>{slogan}</span>
          </p>
        </div>
        {appId && (
          <button
            type='button'
            className={cx('smart-banner__free-try')}
            aria-label='download'
            onClick={downloadHandler}
          >
            <Image src='/assets/icons/smartBanner/go-app-button.png' alt='go-app' width={106} height={53} />
          </button>
        )}
      </div>
    </Container>
  );
};

export default SmartBanner;
