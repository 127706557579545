'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { styled } from '@linaria/react';
import Link from 'next/link';
import { memo } from 'react';

type NavBarProps = {
  data: NavigationItem[];
  active?: string;
};

const Ul = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Li = styled.li<{ active: boolean }>`
  font-size: 16px;

  a {
    color: ${({ active }) => (active ? '#e03f19' : '#848d97')};
    font-weight: ${({ active }) => (active ? 500 : 'normal')};

    &:hover {
      color: #e03f19;
    }
  }

  &:not(:last-child)::after {
    content: '';
    width: 2px;
    height: 16px;
    background-color: #e2e8f1;
    margin: 0 8px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const NavBar = ({ data, active }: NavBarProps) => {
  if (!data || data?.length === 0) return null;

  return (
    <Ul>
      {data?.map(nav => {
        const { name, category, link, hideInDesktop, shortName } = nav;
        if (hideInDesktop) return null;

        const isActive = active?.includes(encodeURIComponent(',')) ?  decodeURIComponent(active) === category : active === category;
        const url = link || `/news/cat/${category}`;
        const GTM_EVENT = {
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
          [GTMEventKey.SECTION]: '%p',
          [GTMEventKey.CLICK_ITEM]: name
        };
        const onClick = () => {
          sendGTM(GTM_EVENT);
        };

        return (
          <Li key={name} active={isActive}>
            <Link href={url} prefetch={false} onClick={onClick}>
              {shortName || name}
            </Link>
          </Li>
        );
      })}
    </Ul>
  );
};

export default memo(NavBar);
