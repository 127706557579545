/* eslint-disable react/boolean-prop-naming */
import Quote from '@fe-common-ui/Quote';
import {
  Colors,
  SvgChart,
  getBaseline,
  getChartUpdateKey,
  getLastUpdateTimestamp,
  reverseHistory,
  useFetchQuote
} from '@fe-common-ui/TrendChart';
import { generateStockLinkBySymbol } from '@fe-common-utils/libs/link';
import { formatTime } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import Image from 'next/image';

type Props = {
  symbol: string;
  width?: number;
  height?: number;
  customName?: string;
  simple?: boolean;
  colorNegative?: string;
  colorPositive?: string;
};

const EMPTY = '--';
const DEFAULT_VALUE = {
  name: EMPTY,
  prevClosePrice: 0,
  session: [],
  value: EMPTY,
  netChange: EMPTY,
  changeInPercentage: EMPTY,
  volume: EMPTY,
  isTrading: false,
  marketStatus: 0
};

const View = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  font-size: 14px;
  line-height: 20px;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
`;

const SubTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Name = styled.a`
  color: white;
`;

const Footer = styled.p`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;

const DateTime = styled.span`
  color: #9b9b9b;
`;

const IndexChart = ({
  customName,
  symbol,
  width = 153,
  height = 52,
  simple = false,
  colorNegative = Colors.Negative,
  colorPositive = Colors.Positive
}: Props) => {
  const { history, quote } = useFetchQuote({ symbol });

  const name = customName || quote?.name || EMPTY;
  const close = quote?.value || quote?.prevClosePrice || EMPTY;
  const percent = quote?.changeInPercentage || EMPTY;
  const change = quote?.netChange || EMPTY;
  const isShow = !simple;
  const updateTime = quote?.session?.[0]?.[1];
  const link = generateStockLinkBySymbol(symbol);

  const renderChart = () => {
    const { session, isTrading } = quote || DEFAULT_VALUE;
    const highlighted = true;
    const { c, t } = reverseHistory({
      c: history?.c ?? [],
      t: history?.t ?? []
    });
    const base = getBaseline({ quote, c });
    const { lastUpdateTimestamp, lastPrice } = getLastUpdateTimestamp({ c, t });
    const chartUpdateKey = getChartUpdateKey({
      windowSize: 'large',
      lastUpdateTimestamp,
      lastPrice,
      isTrading
    });

    return (
      <SvgChart
        updateKey={chartUpdateKey}
        height={height}
        width={width}
        padding={0}
        yGap={0}
        t={t}
        c={c}
        base={base}
        session={session}
        highlightLastPoint={highlighted}
        tooltipByDate={false}
        strokeWidth={2}
        colorNegative={colorNegative}
        colorPositive={colorPositive}
      />
    );
  };

  return (
    <View width={width}>
      <Title>
        <Name href={link}>{name}</Name>
        <Quote change={change} bold='bold'>
          {close}({percent})
        </Quote>
      </Title>
      {isShow && (
        <>
          <SubTitle>
            <Quote change={change} bold='bold'>
              {change}
            </Quote>
          </SubTitle>
          {renderChart()}
          <Footer>
            <DateTime>{formatTime(updateTime, 'MMDDHHMM')}</DateTime>
            <Image src='/assets/icons/logo/anue_logo_dark.svg' alt='anue logo' width={30} height={12} />
          </Footer>
        </>
      )}
    </View>
  );
};

export default IndexChart;
