'use client';

import { SMART_BANNER_CLOSE_EVENT } from '@fe-common-ui/SmartBanner';
import { getCookie } from '@fe-common-utils/libs/cookie';
import { isClient } from '@fe-common-utils/libs/utils';
import { NewsCategory } from '@fe-news/constants/categories';
import { styled } from '@linaria/react';
import { useEffect, useState } from 'react';
import Nav from '../Nav/Nav';

const View = styled.div<{ top?: number }>`
  width: 100%;
  position: fixed;
  top: ${({ top }) => top || 0}px;
  left: 0;
  z-index: 9;
  background-color: white;
`;

const DEFAULT_HEIGHT = 44;
const HEIGHT_WITH_SMARTBANNER = 98;

const TopNavBar = ({ category, isShowTopNavBar }: { category: NewsCategory; isShowTopNavBar: boolean }) => {
  const [top, setTop] = useState(getCookie('smart-banner-closed') ? DEFAULT_HEIGHT : HEIGHT_WITH_SMARTBANNER);

  const onSmartBannerClose = () => {
    setTop(DEFAULT_HEIGHT);
  };

  useEffect(() => {
    if (isClient) {
      window?.addEventListener(SMART_BANNER_CLOSE_EVENT, onSmartBannerClose);
    }

    return () => {
      if (isClient) {
        window.removeEventListener(SMART_BANNER_CLOSE_EVENT, onSmartBannerClose);
      }
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const contentMainMobile = document.getElementById('content-main-mobile');
    // eslint-disable-next-line unicorn/prefer-query-selector
    const goBackButton = document.getElementById('go-back-button');

    // 因 名家頁面 少了 sub nav bar，所以必須要調整 top 位置，才會符合設計稿畫面

    if (contentMainMobile) {
      contentMainMobile.style.paddingTop = isShowTopNavBar
        ? top === 98
          ? '160px'
          : '116px'
        : top === 98
        ? '65px'
        : '0px';
    }

    if (goBackButton) {
      goBackButton.style.top = top === 98 ? '59px' : '5px';
    }
  }, [top, isShowTopNavBar]);

  if (!isShowTopNavBar) {
    return null;
  }

  return (
    <View top={top}>
      <Nav category={category} />
    </View>
  );
};

export default TopNavBar;
