'use client';

import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const NewsSearch = dynamic(() => import('@fe-news/components/molecules/NewsSearch/NewsSearch'), {
  loading: () => <RectangleSkeleton width={104} height={28} borderRadius={3} />,
  ssr: false
});

const DynamicLoadSearchBar = () => {
  return (
    <Suspense>
      <NewsSearch />
    </Suspense>
  );
};

export default DynamicLoadSearchBar;
