import { styled } from '@linaria/react';

const View = styled.div<{ width?: number; height?: number; borderRadius?: number; margin?: string; padding?: string }>`
  @keyframes pulse-animation {
    0% {
      background-color: #f6f8fc;
    }

    50% {
      background-color: #e2e8f1;
    }

    100% {
      background-color: #f6f8fc;
    }
  }

  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  animation: pulse-animation 1.5s ease-in-out 0.5s infinite;
  background-color: #f6f8fc;
  border: 1px solid white;
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}` : 6)}px;
`;

const LoadingAnimationBar = ({
  width,
  height,
  borderRadius,
  margin,
  padding
}: {
  width?: number;
  height?: number;
  borderRadius?: number;
  margin?: string;
  padding?: string;
}) => {
  return (
    <View
      className='loading-animation-bar'
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      borderRadius={borderRadius}
    />
  );
};

export default LoadingAnimationBar;
