'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import LinkFactory from '@fe-common-utils/libs/link';
import { isClient } from '@fe-common-utils/libs/utils';
import { NewsCategory } from '@fe-news/constants/categories';
import { styled } from '@linaria/react';
import cx from 'classnames';
import Link from 'next/link';
import { memo, useCallback } from 'react';

const NavContainer = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: scroll;
  padding: 0 8px;
  border-bottom: 1px solid #e2e8f1;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* Chrome */
    display: none;
  }
`;

const NavItem = styled.li`
  color: #383838;
  white-space: nowrap;
  position: relative;

  a {
    padding: 11px 8px;
    display: block;
    height: 100%;
  }

  &.active {
    a {
      color: #e03f19;
      font-weight: 500;
    }

    &::after {
      content: '';
      width: 20px;
      height: 2px;
      background-color: #e03f19;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 2px 6px 0 rgba(224, 63, 25, 0.5);
    }
  }

  &.sub-tab {
    font-size: 15px;
  }

  &.sub-tab::after {
    content: unset;
  }
`;

type NavTabsProps = {
  navs?: { name: string; category: NewsCategory; link?: string }[];
  category: NewsCategory;
  parent?: NewsCategory;
  isSubTab?: boolean;
};

const onClickTab = (categoryTitle: string) => {
  sendGTM({
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
    [GTMEventKey.SECTION]: GTMConstants.SECTION_PLACEHOLDER,
    [GTMEventKey.CLICK_ITEM]: categoryTitle
  });
};

const NavTabs = ({ navs, category, parent, isSubTab = false }: NavTabsProps) => {
  const moveToActiveTab = useCallback(
    (node?: HTMLUListElement | null) => {
      if (node) {
        const anchors = [...node.querySelectorAll('a')];
        let offset = 0;
        const categoryPath = `${LinkFactory.cnyesBaseUrl}/news/cat/${category}`;
        const parentPath = `${LinkFactory.cnyesBaseUrl}/news/cat/${parent}`;

        for (const anchor of anchors) {
          if (!isSubTab && parent === NewsCategory.HEADLINE) break;
          if (anchor.href.includes(categoryPath)) break;
          if (parent && anchor.href.includes(parentPath)) break;

          offset += anchor.offsetWidth;
        }

        if (isClient && offset > window.innerWidth - 16) {
          node.scrollTo(offset, 0);
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category]
  );

  if (!navs) return null;

  return (
    <NavContainer ref={moveToActiveTab}>
      {navs?.map(nav => {
        const isActive = [category, decodeURIComponent(category), parent].includes(nav.category);
        const { name, link } = nav;
        const href = link || `/news/cat/${nav.category}`;

        return (
          <NavItem
            key={name}
            className={cx({ active: isActive, 'sub-tab': isSubTab })}
            onClick={() => onClickTab(name)}
          >
            <Link href={href}>{name}</Link>
          </NavItem>
        );
      })}
    </NavContainer>
  );
};

export default memo(NavTabs);
