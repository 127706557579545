'use client';

import dynamic from 'next/dynamic';
import LoadingAnimationBar from '@fe-common-ui/LoadingSkeleton/LoadingAnimationBar';
import { styled } from '@linaria/react';

const View = styled.div`
  padding: 20px 0 35px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-animation-bar {
    width: calc(100% - 64px);
  }
`;

const NavigatorCarousel = dynamic(
  () => import('@fe-news/components/IndexMobileWrapper/NavigatorCarousel/NavigatorCarousel'),
  {
    loading: () => (
      <View>
        <LoadingAnimationBar height={203} borderRadius={10} />
      </View>
    ),
    ssr: false
  }
);

const DynamicNavigatorCarousel = ({ news }: { news: News[] }) => {
  return <NavigatorCarousel news={news} />;
};

export default DynamicNavigatorCarousel;
