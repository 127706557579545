type StockProps = {
  mtype?: string | undefined;
  type?: string | undefined;
  market?: string | undefined;
  objectType?: string | undefined;
  exchange?: string | undefined;
};

export const getStockCategory = (item: StockProps): string => {
  const { mtype = '', type = '', market = '', objectType = '' } = item;

  if (objectType === 'FUND') {
    return '基金';
  }

  switch (mtype) {
    case 'STOCK': {
      if (type === 'COMMON') {
        switch (market) {
          case 'TWS':
          case 'TWG':
            return '台股';
          case 'USS':
            return '美股';
          case 'HKS':
          case 'CNS':
            return '陸港股';
          default:
            return '';
        }
      } else {
        return 'ETF';
      }
    }
    case 'FOREX': {
      if (market === 'CC') {
        return '加密貨幣';
      }

      return '外匯';
    }
    case 'FUTURES':
      return '期貨';
    case 'INDEX':
      return '指數';
    case 'EOD':
      return 'EOD';
    default:
      return '';
  }
};

export const getStockExchange = (item: StockProps): string => {
  const { mtype = '', exchange = '', market = '', objectType = '' } = item;

  if (objectType === 'FUND') {
    return '';
  }

  switch (mtype) {
    case 'STOCK':
    case 'FUTURES':
      return exchange;
    case 'FOREX':
      if (market === 'CC') {
        return exchange;
      }

      return '';
    case 'INDEX':
    case 'EOD':
    default:
      return '';
  }
};


/**
 * 取得 Symbol 盤別碼 (TWS/TWG/USS/FX/GI)
 * @param symbol
 * @returns
 */
export const getStockMarket = (symbol: string): string => {
  if (!symbol) return '';

  return String(symbol).split(':')[0] || '';
}

/**
 * 取得 Symbol 的股票代號
 * @param {string} symbol
 * @returns {string}
 */
export const getStockCode = (symbol: string): string => {
  if (!symbol) return '';

  const data = String(symbol).split(':');

  if (!data || data.length < 2) {
    return '';
  }

  return data[1];
};

/**
 * 取得 Symbol 的行情
 * @param {string} symbol
 * @returns {string}
 */
export const getStockFuture = (symbol: string): string => {
  if (!symbol) return '';

  return String(symbol).split(':')[2] || '';
}

export const getStockType = (symbol: string): string => {
  const [market, , productType] = symbol.split(':');

  if (market === 'TWS' || market === 'TWG') {
    return 'STOCK';
  }
  if (productType === 'FUND') {
    return 'FUND';
  }

  return 'GLOBAL_STOCK';
};
