'use client';
import { styled } from '@linaria/react';
import Image from 'next/image';
import { useCallback, useState } from 'react';
import ChartContent from './ChartContent';

const ICON_ARROW_UP = '/assets/icons/arrows/up-white.svg';
const ICON_ARROW_DOWN = '/assets/icons/arrows/down-white.svg';

const View = styled.div<{ isOpen?: boolean }>`
  height: ${({ isOpen }) => (isOpen ? 148 : 40)}px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
`;

const BottomFloatIndex = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsOpen(v => !v);
  }, []);

  return (
    <View isOpen={isOpen}>
      <ChartContent isOpen={isOpen} />
      <Image
        src={isOpen ? ICON_ARROW_DOWN : ICON_ARROW_UP}
        alt='float-arrow'
        width={20}
        height={20}
        onClick={onClick}
      />
    </View>
  );
};

export default BottomFloatIndex;
