import { styled } from '@linaria/react';
import cx from 'classnames';
import { memo } from 'react';

const Wrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Dot = styled.span`
  display: block;
  width: 12px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #b3c0ce;

  &.active {
    background-color: #e03f19;
    width: 24px;
  }
`;

const DotsWrapper = ({ newsNumber = 0, scrollIndex = 0 }: { newsNumber: number; scrollIndex: number }) => {
  return (
    <Wrapper>
      {Array.from({ length: newsNumber }, (_, index) => index).map((_, dotIndex) => (
        <Dot key={dotIndex} className={cx({ active: scrollIndex === dotIndex })} />
      ))}
    </Wrapper>
  );
};

export default memo(DotsWrapper);
