/* eslint-disable unicorn/no-negated-condition */

import { NewsCategory } from '@fe-news/constants/categories';
import { getMobileParentCategory, mobileNav } from '@fe-news/utils/category';
import { styled } from '@linaria/react';
import NavTabs from './NavTabs';

const Container = styled.nav`
  box-shadow: 0 2px 6px 0 rgba(0, 65, 143, 0.1);
`;

const Nav = ({ category }: { category: NewsCategory }) => {
  const parent = getMobileParentCategory(category);
  const mobileSubNav = parent?.navs;

  return (
    <Container className='nav-container'>
      <NavTabs navs={mobileNav} category={category} parent={parent?.category as NewsCategory} />
      <NavTabs navs={mobileSubNav} category={category} isSubTab />
    </Container>
  );
};

export default Nav;
