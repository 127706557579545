import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Image from 'next/image';
import Link from 'next/link';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: solid 1px #e2e8f1;
  background-color: #f6f8fc;
  width: 156px;
  height: 204px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    min-width: 156px;
    width: 100%;
  }
`;

const Figure = styled.figure`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-size: cover;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border: solid 1px #e2e8f1;
  background: #ccc none 50% no-repeat;

  img {
    z-index: 1;
    height: 100%;
    display: block;
  }
`;

const ColumnistName = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  letter-spacing: 0.5px;
  color: #000;
  margin: 16px 0 8px 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const ColumnistSummary = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.57;
  color: #515c69;
  flex: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 100%;
`;

type ColumnistsCardProps = {
  data: ColumnistsType;
};

const ColumnistsCard: React.FC<ColumnistsCardProps> = ({ data }) => {
  const id = data.columnistsId;
  const summary = data.description || '';
  const name = data.name;
  const image = data.image.s.src;

  return (
    <Link href={`/columnists/${id}`}>
      <Card>
        <Figure>
          <Image src={image} alt='Columnist' width={200} height={113} />
        </Figure>
        <ColumnistName>{name}</ColumnistName>
        <ColumnistSummary>{summary}</ColumnistSummary>
      </Card>
    </Link>
  );
};

export default ColumnistsCard;
