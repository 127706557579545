'use client';
import CarouselWords from '@fe-common-ui/Carousel/CarouselWords';
import ReactSlick from '@fe-common-ui/Slick';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { IndexChartType, getIndexChartType } from '@fe-common-utils/libs/time';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import { useCallback, useState } from 'react';
import IndexChart from './IndexChart';

const SLIDER_SETTINGS = {
  dots: true,
  arrows: false
};

const CHART_WIDTH = 163;

const currentChartIndex = getIndexChartType();
const INDEXS = {
  [IndexChartType.TW]: [
    { symbol: 'TWS:TSE01:INDEX', customName: '加權' },
    { symbol: 'TWS:OTC01:INDEX', customName: '櫃買' },
    { symbol: 'GI:HSI:INDEX', customName: '恆生' },
    { symbol: 'GI:SSEC:INDEX', customName: '上證' }
  ],
  [IndexChartType.UK]: [
    { symbol: 'GF:NQ:FUTURES', customName: 'E-Mini' },
    { symbol: 'TWF:TXF:FUTURES', customName: '台指期' },
    { symbol: 'GI:FTSE:INDEX', customName: '英國' },
    { symbol: 'GI:CAC:INDEX', customName: '法國' }
  ],
  [IndexChartType.US]: [
    { symbol: 'GI:DJI:INDEX', customName: '道瓊' },
    { symbol: 'GI:IXIC:INDEX', customName: '納指' },
    { symbol: 'GI:INX:INDEX', customName: '標普' },
    { symbol: 'GI:SOX:INDEX', customName: '費半' }
  ]
};

const CHART_SYMBOLS = [INDEXS[currentChartIndex].slice(0, 2), INDEXS[currentChartIndex].slice(2)];

const CustomSlider = styled(ReactSlick)`
  && .slick-dots {
    button:before {
      content: '';
      width: 12px;
      height: 3px;
      background-color: #b3c0ce;
      border-radius: 1.5px;
      padding: 0;
      display: inline-block;
      opacity: 1;
    }

    button,
    li {
      width: 12px;
    }

    .slick-active {
      width: 24px;
    }

    .slick-active button:before {
      opacity: 1;
      background: #e03f19 !important;
      width: 24px;
    }
  }
`;

const View = styled.div`
  overflow: hidden;
  flex: 1;
  .fall {
    color: #2bad09 !important;
  }
`;

const SliderItem = styled.div`
  display: flex !important;
  justify-content: space-around;
`;

const FlipItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fall {
    color: #2bad09 !important;
  }

  .flip-container {
    margin-right: 8px !important;
  }
`;

const ChartContent = ({ isOpen }: { isOpen: boolean }) => {
  const isTablet = useMediaQuery(`(min-width:${SCREEN_SIZE.MOBILE}px)`);
  const [chartWidth, setWidth] = useState(CHART_WIDTH);

  const setReference = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      // 最小寬設定為CHART_WIDTH, 當手機畫面變寬時調整寬度且不小於CHART_WIDTH
      const newChartWidth = node.getBoundingClientRect().width / 2 - 16;
      setWidth(w => (newChartWidth < w ? w : newChartWidth));
    }
  }, []);

  // 收起來時顯示跑馬燈(排除平板)
  if (!isOpen && !isTablet) {
    return (
      <View ref={setReference}>
        <CarouselWords height={20}>
          {CHART_SYMBOLS.map((subItem, index) => (
            <FlipItem key={`flip-${index}`}>
              {subItem.map(({ customName, symbol }) => (
                <IndexChart
                  key={symbol}
                  symbol={symbol}
                  customName={customName}
                  simple={!isOpen}
                  colorNegative='43,173,9'
                  width={chartWidth}
                />
              ))}
            </FlipItem>
          ))}
        </CarouselWords>
      </View>
    );
  }

  const renderMobile = () => (
    <CustomSlider {...SLIDER_SETTINGS}>
      {CHART_SYMBOLS.map((sliders, index) => (
        <SliderItem key={`slider-page-${index}`}>
          {sliders.map(({ symbol, customName }) => (
            <IndexChart
              key={symbol}
              symbol={symbol}
              customName={customName}
              simple={!isOpen}
              colorNegative='43,173,9'
              width={chartWidth}
            />
          ))}
        </SliderItem>
      ))}
    </CustomSlider>
  );

  const renderTablet = () => (
    <SliderItem>
      {CHART_SYMBOLS.flat().map(({ symbol, customName }) => (
        <IndexChart key={symbol} symbol={symbol} customName={customName} simple={!isOpen} />
      ))}
    </SliderItem>
  );

  // 展開時顯示指數小圖
  return <View ref={setReference}>{isTablet ? renderTablet() : renderMobile()}</View>;
};

export default ChartContent;
